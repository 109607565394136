<template>
  <div>
    <!-- 上半部 -->
    <div class="row mb-1">
      <!-- 按鈕 -->
      <div class="col-md-5 col-12 mb-1">
        <!-- 進階搜尋 -->
        <button
          class="me-1 mb-1 tw-btn tw-btn-secondary tw-btn-hover"
          @click="showModal('advancedSearch')"
        >
          進階搜尋
        </button>
      </div>
      <!-- 訊息 -->
      <div class="col-md-7 col-12 mb-1 fw-bolder">
        <p>
          搜尋條件:
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.principalName"
          >
            負責人:{{ recordAdvancedSearchData.principalName }}
          </span>
          <span
            class="badge bg-primary me-1 mb-1"
            v-if="recordAdvancedSearchData.receiptNumber"
          >
            發票號碼:{{ recordAdvancedSearchData.receiptNumber }}
          </span>
          <span class="badge bg-primary me-1 mb-1">
            訂閱狀態:{{ recordAdvancedSearchData.singleSelect.orderStatus }}
          </span>
          <span class="badge bg-primary me-1 mb-1">
            刪除狀態:{{ recordAdvancedSearchData.singleSelect.delStatus }}
          </span>
          <span class="badge bg-primary me-1 mb-1">
            訂閱模式:{{ recordAdvancedSearchData.singleSelect.orderMode }}
          </span>
        </p>
        <p v-if="recordAdvancedSearchData.timeRange.switch">
          搜尋區間:
          <span
            v-if="
              recordAdvancedSearchData.timeRange.startTime &&
              recordAdvancedSearchData.timeRange.endTime
            "
            >{{ recordAdvancedSearchData.timeRange.startTime }}~{{
              recordAdvancedSearchData.timeRange.endTime
            }}</span
          >
          <span
            v-if="
              recordAdvancedSearchData.timeRange.startTime &&
              !recordAdvancedSearchData.timeRange.endTime
            "
            >{{ recordAdvancedSearchData.timeRange.startTime }}開始</span
          >
          <span
            v-if="
              !recordAdvancedSearchData.timeRange.startTime &&
              recordAdvancedSearchData.timeRange.endTime
            "
            >{{ recordAdvancedSearchData.timeRange.endTime }}以前</span
          >
        </p>
      </div>
    </div>
    <!-- dataTable -->
    <div class="tw-container">
      <div class="management-datatable">
        <DataTable
          class="p-datatable-sm"
          :scrollHeight="`${scrollHeight}px`"
          :value="paymentRecordList"
          dataKey="id"
          :loading="dataTabelLoading"
          :rowHover="true"
          :paginator="true"
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rows="20"
          :rowsPerPageOptions="[20, 50, 100]"
          :scrollable="true"
          currentPageReportTemplate="從第 {first} 筆到 第 {last} 筆，總共 {totalRecords} 筆"
          v-model:filters="searchGlobal"
          filterDisplay="menue"
          stateStorage="local"
          stateKey="dt-state-purchaseConfimation-local"
          sortField="createTime"
          :sortOrder="-1"
          v-model:selection="selectItems"
        >
          <template #header>
            <div class="d-flex align-items-center">
              <input
                class="form-control me-2"
                v-model="searchGlobal['global'].value"
                placeholder="表格搜尋: 訂閱ID，使用者ID，使用者帳號，匯款金額，訂單建立日期"
                style="max-width: 99%"
              />
            </div>
          </template>
          <!-- 訂閱ID -->
          <Column
            field="id"
            header="訂閱ID"
            sortable
            style="min-width: 80px;max-width:100px"
          ></Column>
          <!-- 使用者ID -->
          <Column
            field="ownerId"
            header="使用者ID"
            sortable
            style="min-width: 80px;max-width:110px"
          ></Column>
          <!-- 使用者帳號 -->
          <Column
            field="owner.account"
            header="使用者帳號"
            sortable
            style="min-width: 230px"
          ></Column>
          <!-- 回報資訊 -->
          <Column field="" header="回報資訊" style="min-width: 150px">
            <template #body="{ data }">
              <p class="text-success" v-if="data.planType === 'NORMAL'">
                <p class="pointer can-click" v-if="data.paymentNotification.userPaymentInfo" @click="showModal('checkPaymentNotification', data)">
                  <p class="mb-1">銀行號: {{ data.paymentNotification.userPaymentInfo.bankCode }}</p>
                  <p class="mb-1">後五碼: {{ data.paymentNotification.userPaymentInfo.accountNumber }}</p>
                  <p class="text-danger" v-if="data.recommenderId">有輸入推薦碼</p>
                </p>
                <p v-else>
                  <span class="text-success" v-if="data.paymentVerifyRecords.length > 0 && data.paymentVerifyRecords[data.paymentVerifyRecords.length - 1].administrativeUserId === 1">未回報</span>
                  <span class="text-danger" v-else>未回報</span>
                </p>
              </p>
              <p class="pointer can-click" v-else-if="data.planType === 'FREE_TRIAL'" @click="showModal('checkPaymentNotification', data)">試用期方案</p>
              <p v-else-if="data.planType === 'RECOMMENDED_BONUS'">推薦人獎勵</p>
            </template>
          </Column>
          <!-- 匯款金額 -->
          <Column
            field="moneyTransfer"
            sortable
            header="匯款金額"
            style="min-width: 75px"
          >
          </Column>
          <!-- 審核狀態 -->
          <Column field="paid" header="審核狀態"  style="min-width: 75px" sortable>
            <template #body="{ data }">
              <p
                class="text-success fw-bolder pointer can-click"
                v-if="data.paid"
                @click="showModal('checkVerifyInfo', data)"
              >
                通過
              </p>
              <p
                class="text-danger fw-bolder pointer can-click"
                v-else-if="!data.paid && data.paymentVerifyRecords.length > 0"
                @click="showModal('checkVerifyInfo', data)"
              >
                未通過
              </p>
              <p
                class="text-warning fw-bolder"
                v-else-if="!data.paid && data.paymentVerifyRecords.length === 0"
              >
                待審核
              </p>
            </template>
          </Column>
          <!-- 發票號碼 -->
          <Column field="" header="發票號碼" style="min-width: 85px">
            <template #body="{ data }">
              <!-- 審核通過且有發票號碼 -->
              <p
                class="text-success fw-bolder"
                v-if="
                  data.paid &&
                  data.paymentVerifyRecords[
                    data.paymentVerifyRecords.length - 1
                  ].receiptNumber
                "
              >
                {{
                  data.paymentVerifyRecords[
                    data.paymentVerifyRecords.length - 1
                  ].receiptNumber
                }}
              </p>
              <!-- 審核通過但沒有發票號碼 -->
              <p
                class="text-success fw-bolder"
                v-else-if="
                  data.paid &&
                  !data.paymentVerifyRecords[
                    data.paymentVerifyRecords.length - 1
                  ].receiptNumber
                "
              >
                <p class="text-success fw-bolder" v-if="data.planType === 'FREE_TRIAL' || data.administrativeUser === 'admin'">
                  免開立
                </p>
                <button
                  class="tw-btn tw-btn-danger"
                  @click="showModal('editReceipt', data)"
                  v-else
                >
                  新增發票
                </button>
              </p>
              <!-- 審核未通過 -->
              <p
                class="text-danger fw-bolder"
                v-else-if="!data.paid && data.paymentVerifyRecords.length > 0"
              >
                {{
                  data.paymentVerifyRecords[
                    data.paymentVerifyRecords.length - 1
                  ].receiptNumber
                }}
              </p>
              <!-- 待審核 -->
              <p
                class="text-warning fw-bolder"
                v-else-if="!data.paid && data.paymentVerifyRecords.length === 0"
              >
                待審核
              </p>
            </template>
          </Column>
          <!-- 發票寄出 -->
          <Column field="shipped" header="發票寄出" sortable  style="min-width: 80px">
            <template #body="{ data }">
              <p class="text-success fw-bolder" v-if="data.shipped">已寄出</p>
              <p
                class="text-success fw-bolder"
                v-else-if="data.planType === 'FREE_TRIAL' || data.administrativeUser === 'admin'"
              >
                免寄出
              </p>
              <p
                class="text-danger fw-bolder pointer can-click"
                v-else
                @click="showModal('shipped', data)"
              >
                未寄出
              </p>
            </template>
          </Column>
          <!-- 負責人 -->
          <Column field="administrativeUser" header="負責人" sortable>
            <template #body="{ data }">
              <p
                class="text-danger "
                v-if="data.paymentVerifyRecords.length === 0"
              >
                尚未處理
              </p>
              <p v-else>
                {{ data.administrativeUser }}
              </p>
            </template>
          </Column>
          <!-- 訂單建立日期 -->
          <Column field="createTime" header="訂單建立日期" sortable style="min-width: 100px">
          </Column>
          <template #empty>
            <p class="text-center fw-bolder text-primary">
              目前尚未有任何資料 0..0
            </p>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
  <!-- 檢視 paymentNotification 的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="paymentNotificationModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">回報資訊</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <ul class="list">
            <li class="fw-bolder">訂單項目: </li>
            <li v-if="paymentRecordData.paymentRecordProjectLinks.length > 0">。訂閱: {{ paymentRecordData.paymentRecordProjectLinks[0].period }}個月</li>
            <li v-if="paymentRecordData.paymentRecordPermissionLinks.length > 0">
              。小幫手 {{ paymentRecordData.paymentRecordPermissionLinks.length }} 人:
              <p v-for="(helper, index) in paymentRecordData.paymentRecordPermissionLinks" :key="helper.id">
                &ensp;&ensp;{{ index + 1 }}. ID: {{ helper.id }}, 買 {{ helper.period }} 月
              </p>
            </li>
            <li><hr></li>
            <li class="fw-bolder">訂單內容: </li>
            <li>訂單備註: <pre v-if="paymentRecordData.note" class="alert-warning p-1">{{ paymentRecordData.note }}</pre></li>
            <li>訂單建立時間: {{ $methods.moment(paymentRecordData.createTime).format('YYYY-MM-DD HH:mm:ss') }}</li>
            <li class="text-danger fw-bolder">使用積分: {{ paymentRecordData.integrall }}</li>
            <li class="text-danger fw-bolder">最後訂單金額: {{ paymentRecordData.money }}</li>
            <li><hr></li>
            <li class="fw-bolder">付款資訊: </li>
            <li>付款者名稱: {{ paymentRecordData.owner.name }}</li>
            <li>付款者帳號: {{ paymentRecordData.owner.account }}</li>
            <li>付款者電話: {{ paymentRecordData.phoneNumber }}</li>
            <li class="text-danger fw-bolder">付款金額: {{ paymentRecordData.paymentNotification.money }}</li>
            <li>帳號末五碼: {{ paymentRecordData.paymentNotification.accountNumber }}</li>
            <li>銀行號: {{ paymentRecordData.paymentNotification.bankCode }}</li>
            <li>付款備註: {{ paymentRecordData.paymentNotification.note }}</li>
            <li>付款時間: {{ $methods.moment(paymentRecordData.paymentNotification.paymentTime).format('YYYY-MM-DD HH:mm:ss') }}</li>
            <li><hr></li>
            <li class="fw-bolder">發票資訊: </li>
            <li>發票抬頭: {{ paymentRecordData.title }}</li>
            <li>統一編號: {{ paymentRecordData.businessAdminNumber }}</li>
            <li>發票地址: {{ paymentRecordData.address }}</li>
          </ul>
          <hr>
          <div class="alert-warning p-3 mb-3">
            <p class="fw-bolder mb-2">二周年活動 (2024/09/30 前截止)</p>
            <p class="mb-2">活動內容: 只要有輸入推薦碼的單，雙方都可以擁有「Line 自定義指令功能」(不管新舊用戶，不管年繳季繳)</p>
            <p class="alert-danger p-2 fw-bolder">。只要有推薦碼就點按紐</p>
            <hr>
            <ul class="list">
              <li class="alert-info p-2">推薦碼: {{ paymentRecordData.recommenderId }}</li>
              <li><button class="tw-btn tw-btn-success" @click="giveAwayLineKeywords">開通雙方的「Line 自訂群組指令」功能</button></li>
            </ul>
          </div>
          <div class="alert-warning p-3 mb-3">
            <p class="fw-bolder mb-2">二周年活動 (2024/12/31 前截止)</p>
            <p class="mb-2">活動內容: 條件(新用戶、年繳、有推薦碼)，就送推薦人 1500 積分</p>
            <p class="alert-danger p-2 fw-bolder">。訂單備註有「2024-12-31前的優惠活動，新用戶&&年繳&&有推薦碼」就點按紐</p>
            <hr>
            <ul class="list">
              <li class="alert-info p-2">推薦碼: {{ paymentRecordData.recommenderId }}</li>
              <li class="alert-info p-2">
                <p class="mb-2">訂單備註:</p>
                <pre v-if="paymentRecordData.note">{{ paymentRecordData.note }}</pre>
              </li>
              <li><button class="tw-btn tw-btn-success" @click="giveAway1500Integrall">贈送推薦人 1500 積分</button></li>
            </ul>
          </div>
          <!-- 待核准 -->
          <div v-if="!paymentRecordData.paid && paymentRecordData.paymentVerifyRecords.length === 0">
            <!-- 發票號碼 -->
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="ReceiptNumber" placeholder="發票號碼" v-model="verifyData.receiptNumber" maxlength="10" :disabled="paymentRecordData.planType === 'FREE_TRIAL'">
              <label for="ReceiptNumber">發票號碼(10字)</label>
            </div>
            <!-- 備註 -->
            <div class="form-floating mb-3">
              <textarea
                class="form-control"
                placeholder="備註"
                id="Note"
                style="height: 55px"
                maxlength="300"
                v-model="verifyData.note"
                :disabled="paymentRecordData.planType === 'FREE_TRIAL'"
              ></textarea>
              <label for="Note">備註(300字)</label>
            </div>
          </div>
          <!-- 未通過核准 -->
          <p class="text-danger fw-bolder" v-else-if="!paymentRecordData.paid && paymentRecordData.paymentVerifyRecords.length > 0">
            此訂閱已審核 "未通過"!
          </p>
          <!-- 通過核准 -->
          <div v-else-if="paymentRecordData.paid">
            <p class="text-success fw-bolder mb-3" >
              此訂閱已審核 "通過"!
            </p>
            <div>
              <p class="fw-bolder mb-2">審核備註:</p>
              <textarea name="" id="" cols="30" rows="10" v-model="paymentRecordData.paymentVerifyRecords[0].note" disabled></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            v-if="!paymentRecordData.paid && !paymentRecordData.paymentVerifyRecords.length > 0 && paymentRecordData.planType !== 'FREE_TRIAL'"
            type="button"
            class="tw-btn tw-btn-danger"
            @click="verifyPaymentRecord(false)"
          >
            拒絕
          </button>
          <button
            v-if="!paymentRecordData.paid && !paymentRecordData.paymentVerifyRecords.length > 0"
            type="button"
            class="tw-btn tw-btn-success"
            @click="verifyPaymentRecord(true)"
          >
            核准
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 檢視審核資訊的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="checkVerifyInfoModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">審核資訊</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <table class="table table-hover">
            <thead>
              <tr>
                <th>負責人</th>
                <th>發票號碼</th>
                <th>審核結果</th>
                <th>審核備註</th>
                <th>審核日期</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="data in paymentVerifyRecords" :key="data.id">
                <td>
                  {{ data.administrativeUser.name }}
                </td>
                <td>
                  <p class="fw-bolder">{{ data.receiptNumber }}</p>
                </td>
                <td>
                  <p class="text-success" v-if="data.verify">通過</p>
                  <p class="text-danger" v-else>未通過</p>
                </td>
                <td>
                  <p>{{ data.note }}</p>
                </td>
                <td>
                  {{ $methods.moment(data.createTime).format('YYYY-MM-DD HH:mm:ss') }}
                </td>
              </tr>
            </tbody>  
          </table>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 寄出發票的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="shippedModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">寄出發票</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div
            v-if="subscribeProject.paymentVerifyRecords.length > 0 && subscribeProject.paymentVerifyRecords[subscribeProject.paymentVerifyRecords.length - 1].administrativeUserId === 1"
          >
            <p class="text-danger fw-bolder mb-3">此為系統自動建立，故不必寄送發票!</p>
          </div>
          <div v-else-if="!subscribeProject.paid && subscribeProject.paymentVerifyRecords.length > 0">
            <p class="text-danger fw-bolder mb-3">審核狀態未通過，故不必寄送發票!</p>
          </div>
          <div v-else>
            <p class="mb-2">確定將 <span class="fw-bold text-primary">訂閱ID: {{ subscribeProject.id }}</span> 發票寄出嗎?</p>
            <!-- 寄出時間 -->
            <div class="mb-3">
              <label for="shippedTime" class="form-label fw-bold">寄出時間:</label>
              <input
                class="form-control"
                type="datetime-local"
                id="ShippedTime"
                v-model="shippedTime"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success"
            v-if="subscribeProject.paymentVerifyRecords.length > 0 
            && subscribeProject.paymentVerifyRecords[subscribeProject.paymentVerifyRecords.length - 1].administrativeUserId !== 1
            && subscribeProject.paymentVerifyRecords[subscribeProject.paymentVerifyRecords.length - 1].receiptNumber
            "
            @click="sendReceipt"
          >確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 新增發票的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="editReceiptModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">新增發票</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="alert alert-danger" role="alert">
            注意! 此動作一旦確定就無法更改。
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="ReceiptNumber" placeholder="發票號碼" maxlength="10" v-model="receiptNumber">
            <label for="ReceiptNumber">發票號碼(10字)</label>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success"  @click="updateReceipt">確認</button>
        </div>
      </div>
    </div>
  </div>
  <!-- 進階搜尋的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="advancedSearchModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bold">進階搜尋</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- 時間區間 -->
          <div class="mb-3">
            <!-- 開關 -->
            <div class="form-check form-switch p-0 mb-2">
              <input
                class="form-check-input m-0 me-2"
                type="checkbox"
                role="switch"
                id="AS_TimeSwitch"
                v-model="advancedSearchData.timeRange.switch"
              />
              <label class="form-check-label" for="AS_TimeSwitch"
                >時間區間</label
              >
            </div>
            <!-- 時間 -->
            <div class="row mb-3" v-if="advancedSearchData.timeRange.switch">
              <!-- 起始時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_StartTime"
                    placeholder="起始時間"
                    v-model="advancedSearchData.timeRange.startTime"
                  />
                  <label for="AS_StartTime" class="mb-2">起始時間</label>
                </div>
              </div>
              <!-- 結束時間 -->
              <div class="col-6 p-0">
                <div class="form-floating">
                  <input
                    class="form-control"
                    type="date"
                    id="AS_EndTime"
                    placeholder="結束時間"
                    v-model="advancedSearchData.timeRange.endTime"
                  />
                  <label for="AS_EndTime" class="mb-2">結束時間</label>
                </div>
              </div>
            </div>
          </div>
          <!-- 負責人 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="PRINCIPAL_Name"
              placeholder="負責人"
              v-model="advancedSearchData.principalName"
            />
            <label for="PRINCIPAL_Name">負責人</label>
          </div>
          <!-- 發票號碼 -->
          <div class="form-floating mb-3">
            <input
              type="text"
              class="form-control"
              id="RECEIPT_NUMBER"
              placeholder="發票號碼"
              v-model="advancedSearchData.receiptNumber"
            />
            <label for="RECEIPT_NUMBER">發票號碼</label>
          </div>
          <!-- 篩選條件 -->
          <div class="border mb-3 p-3">
            <p class="tw-border-start fw-bolder">篩選條件</p>
            <hr />
            <ul class="list">
              <li
                class="row border-bottom"
                v-for="data in singleSelectsArray"
                :key="data.id"
              >
                <div class="col-3 my-2">{{ data.key }}</div>
                <div class="col-9">
                  <div
                    class="select-radio my-2"
                    v-for="select in data.value"
                    :key="select.id"
                  >
                    <label>
                      <input
                        type="radio"
                        :name="data.id"
                        :value="select.value"
                        v-model="advancedSearchData.singleSelect[data.id]"
                      />
                      <span class="radio-style">{{ select.value }}</span>
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button
            type="button"
            class="tw-btn tw-btn-success"
            @click="advancedSearch(true)"
          >
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- 復原的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref="recoverModal"
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header alert alert-primary">
          <h5 class="modal-title tw-border-start border-primary
              text-primary fw-bolder">復原</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <SelectInfos :propsSelectInfos="propsSelectInfos"></SelectInfos>
          是否將所選擇到的訂單復原?
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success" @click="setDeleted(false)">確認</button>
        </div>
      </div>
    </div>
  </div>
  <!-- 空的 modal -->
  <div
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="staticBackdropLabel"
    aria-hidden="true"
    ref=""
  >
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title tw-border-start fw-bolder">Title</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">...</div>
        <div class="modal-footer">
          <button
            type="button"
            class="tw-btn tw-btn-secondary"
            data-bs-dismiss="modal"
          >
            關閉
          </button>
          <button type="button" class="tw-btn tw-btn-success">確認</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// dataTable
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';     //optional for column grouping
import Row from 'primevue/row';                     //optional for row
import { FilterMatchMode } from 'primevue/api';
// api
import { parseToken, sortoutParseTokenResult } from '../../../methods/API/parseToken'
import { userApi } from '../../../methods/API/normal/userApi'
import { systemApi } from '../../../methods/API/normal/systemApi'

export default {
  components: {DataTable, Column, ColumnGroup, Row},
  data() {
    return {
      // modal
      paymentNotificationModal: {},
      checkVerifyInfoModal: {},
      shippedModal: {},
      editReceiptModal: {},
      advancedSearchModal: {},
      recoverModal: {},
      // data
      b_serverToken: '',
      userInfo: {},
      // dataTable
      scrollWidth: 0,
      scrollHeight: 0,
      dataTabelLoading: false,
      paymentRecordList: [],
      searchGlobal: {
        global: {
          value: null,
          matchMode: FilterMatchMode.CONTAINS
        }
      },
      // 選擇到的物件
      selectItems: [],
      // modal 內呈現選擇到的物件資訊
      propsSelectInfos: {
        show: 'id',
        data: []
      },
      // 檢視回報資訊的資料
      paymentRecordData: {
        planType: 'NORMAL',
        owner: {
          id: null,
          name: '',
          account: '',
        },
        paymentNotification: {
          money: 0,
          accountNumber: 0,
          bankCode: 0,
          note: '',
          paymentTime: '',
        },
        id: null,
        title: '',
        businessAdminNumber: 0,
        address: '',
        note: '',
        createTime: '',
        integrall: 0,
        money: 0,
        recommenderId: null,
        paymentVerifyRecords: [],
        paymentRecordPermissionLinks: [],
        paymentRecordProjectLinks: [],
      },
      // 審核通過的資料
      verifyData: {
        paymentRecordId: 0,
        receiptNumber: '',
        note: ''
      },
      // 審核紀錄
      paymentVerifyRecords: [],
      // 寄出發票的資訊
      subscribeProject: {
        paymentVerifyRecords: [],
      },
      shippedTime: '',
      // 編輯發票
      receiptNumber: '',
      // 進階搜尋
      advancedSearchData: {
        timeRange: {
          switch: true,
          startTime: null,
          endTime: null
        },
        principalName: '',
        receiptNumber: '',
        singleSelect: {
          orderStatus: '未完成',
          delStatus: '未刪除',
          orderMode: '全部'
        }
      },
      recordAdvancedSearchData: {},
      // 單選篩選陣列
      singleSelectsArray: [],
    }
  },
  created() {
    this.initialization()
    this.getUserInfo()
    this.advancedSearch('created')
  },
  mounted() {
    this.createModals(['paymentNotificationModal','checkVerifyInfoModal','shippedModal','editReceiptModal',
      'advancedSearchModal', 'recoverModal'
    ])
  },
  unmounted() {
    // 將 dataTable 的紀錄清除
    localStorage.removeItem('dt-state-purchaseConfimation-local')
  },
  methods: {
    // 初始化
    initialization() {
      this.b_serverToken = this.$methods.getCookie('b_serverToken') // 待確認
      // 紀錄 dataTabel 高度
      this.scrollHeight = window.innerHeight - 210
      // 將 dataTable 的紀錄清除
      localStorage.removeItem('dt-state-purchaseConfimation-local')
      // 紀錄進階搜尋單選條件
      this.singleSelectsArray = [{
        id: 'orderStatus',
        key: '訂閱狀態',
        value: [
          {
            id: 'A1',
            value: '未完成'
          }, 
          {
            id: 'A2',
            value: '已完成'
          },
          {
            id: 'A3',
            value: '全部'
          }
        ]
      },
      {
        id: 'delStatus',
        key: '刪除狀態',
        value: [
          {
            id: 'B1',
            value: '未刪除'
          }, 
          {
            id: 'B2',
            value: '已刪除'
          },
          {
            id: 'B3',
            value: '全部'
          }
        ]
      },
      {
        id: 'orderMode',
        key: '訂閱模式',
        value: [
          {
            id: 'C1',
            value: '全部'
          }, 
          {
            id: 'C2',
            value: '一般付費'
          },
          {
            id: 'C3',
            value: '試用期'
          },
          {
            id: 'C4',
            value: '推薦人'
          },
          {
            id: 'C5',
            value: '輸入推薦碼的人'
          },
        ]
      }
      ]
      // 進階搜尋
      this.advancedSearchData.timeRange.endTime = this.$methods.moment().format('YYYY-MM-DD')
      this.advancedSearchData.timeRange.startTime = this.$methods.moment().subtract(90, 'days').format('YYYY-MM-DD')
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
    },
    // 開啟 modal
    showModal(status, item) {
      if (status === 'checkPaymentNotification') {
        // 檢視回報資訊
        console.log(item)
        this.paymentRecordData = {
          planType: item.planType,
          owner: {
            id: item.owner.id,
            name: item.owner.name,
            account: item.owner.account
          },
          paymentNotification: {
            money: item.paymentNotification.money,
            accountNumber: item.planType === 'NORMAL' ? item.paymentNotification.userPaymentInfo.accountNumber : '試用期',
            bankCode: item.planType === 'NORMAL' ? item.paymentNotification.userPaymentInfo.bankCode : '試用期',
            note: item.paymentNotification.note,
            paymentTime: item.paymentNotification.paymentTime,
          },
          id: item.id,
          title: item.title,
          businessAdminNumber: item.businessAdminNumber,
          address: '',
          note: item.note,
          createTime: item.createTime,
          integrall: item.integrall,
          money: item.money,
          recommenderId: item.recommenderId,
          paid: item.paid,
          paymentVerifyRecords: item.paymentVerifyRecords,
          paymentRecordPermissionLinks: item.paymentRecordPermissionLinks,
          paymentRecordProjectLinks: item.paymentRecordProjectLinks,
          phoneNumber: item.phoneNumber,
        }
        this.verifyData = {
          paymentRecordId: item.id,
          receiptNumber: '',
          note: ''
        }
        if (item.postalCode) this.convertPostalCode(item)
        this.paymentNotificationModal.show()
      } else if (status === 'checkVerifyInfo') {
        // 檢視審核資訊
        this.paymentVerifyRecords = item.paymentVerifyRecords
        this.checkVerifyInfoModal.show()
      } else if (status === 'shipped') {
        // 寄出發票
        this.subscribeProject = item
        this.shippedTime = ''
        this.shippedModal.show()
      } else if (status === 'editReceipt') {
        // 編輯發票(只有在尚未有發票紀錄才能編輯)
        this.receiptNumber = ''
        this.subscribeProject = item
        this.editReceiptModal.show()
      } else if (status === 'advancedSearch') {
        // 進階搜尋
        this.advancedSearchData = JSON.parse(JSON.stringify(this.recordAdvancedSearchData))
        this.advancedSearchModal.show()
      } else if (status === 'del') {
        // 刪除
        this.selectItems = []
        this.selectItems.push(item)
        this.propsSelectInfos.data = this.selectItems
        this.delModal.show()
      } else if (status === 'recover') {
        // 復原
        this.selectItems = []
        this.selectItems.push(item)
        this.propsSelectInfos.data = this.selectItems
        this.recoverModal.show()
      }
    },
    // 取得使用者資訊
    getUserInfo() {
      this.$methods.switchLoading('show')
      const result = parseToken(this.b_serverToken)
      result.then(res => {
        const result = sortoutParseTokenResult(res)
        this.userInfo = result
        if (this.userInfo.name === 'Paul'){
          this.$router.push({name: 'UsedInfo'})
        }
      }).catch(err => {
        console.log(err.responseJSON)
        this.SweetAlert('other', err.responseJSON.message)
        this.$methods.switchLoading('hide')
      })
    },
    // 進階搜尋
    advancedSearch(status) {
      this.dataTabelLoading = true
      this.$methods.switchLoading('show')
      this.recordAdvancedSearchData = JSON.parse(JSON.stringify(this.advancedSearchData))
      const query = {"columns":[{"columnName":"id","siteObjectType":53}],"group":{"groups":[{"column":{"columnName":"id","siteObjectType":53},"operator":{"operator":"!=","type":1,"value":0}}],"operator":1},"siteObjectType":53,"sqlCommandType":1}
      // 開啟時間區間
      if (this.advancedSearchData.timeRange.switch) {
        if (this.advancedSearchData.timeRange.startTime) {
          const startTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.startTime} 00:00:00`) / 1000 
          query.group.groups.push({
            column: {
              columnName: 'createTime',
              siteObjectType: 53
            },
            operator: {
              operator: ">=",
              type: 1,
              value: {
                name: `from_unixtime(${startTime})`
              }
            }
          })
        }
        if (this.advancedSearchData.timeRange.endTime) {
          if (this.advancedSearchData.timeRange.endTime) {
            const endTime = this.$methods.convertTimeZone(`${this.advancedSearchData.timeRange.endTime} 23:59:59`) / 1000
            query.group.groups.push({
              column: {
                columnName: 'createTime',
                siteObjectType: 53
              },
              operator: {
                operator: "<=",
                type: 1,
                value: {
                  name: `from_unixtime(${endTime})`
                }
              }
            })
          }
        }
      }
      this.getPaymentRecord(query)
      if (status !== 'created') this.advancedSearchModal.hide()
    },
    // 取得付費紀錄
    getPaymentRecord(query) {
      const vm = this
      const getPaymentRecordApi = `${process.env.VUE_APP_API}/search/getSiteObjects`
      const header = {
        authorization: this.b_serverToken
      }
      const data = [
        {
          query: query,
          methods: '{getPaymentRecordProjectLinks,getPaymentRecordPermissionLinks,getOwner,getPaymentNotification{getUserPaymentInfo},getPaymentVerifyRecords{getAdministrativeUser},isPaid}'
        }
      ]
      $.ajax({
        type: 'POST',
        async: true,
        url: getPaymentRecordApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const paymentRecords = res.data[0].objects
            vm.paymentRecordMap(paymentRecords)
          } else {
            vm.SweetAlert(res.code, res.message)
            vm.$methods.switchLoading('hide')
          }
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert('other', '系統錯誤，請盡速聯絡工程部')
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 付費紀錄資料整理
    paymentRecordMap(paymentRecords) {
      this.paymentRecordList = []
      paymentRecords.forEach(item => {
        // 原資料
        const object = {
          address: item.address,
          businessAdminNumber: item.businessAdminNumber,
          class: item.class,
          createTime: this.$methods.moment(item.createTime).format("YYYY-MM-DD HH:mm:ss"),
          deleted: item.deleted,
          discountAmount: item.discountAmount,
          id: item.id,
          integrall: item.integrall,
          money: item.money,
          moneyTransfer: 0,
          note: item.note,
          owner: item.owner,
          ownerId: item.ownerId,
          paid: item.paid,
          paymentNotification: item.paymentNotification,
          paymentVerifyRecords: item.paymentVerifyRecords,
          paymentRecordPermissionLinks: item.paymentRecordPermissionLinks,
          paymentRecordProjectLinks: item.paymentRecordProjectLinks,
          phoneNumber: item.phoneNumber,
          planType: item.planType,
          postalCode: item.postalCode,
          recommenderId: item.recommenderId,
          shipped: item.shipped,
          shippedTime: item.shippedTime,
          title: item.title,
          administrativeUser: null,
        }
        // 需特別處理
        // 處理負責人
        if (item.paymentVerifyRecords.length > 0) object.administrativeUser = item.paymentVerifyRecords[0].administrativeUser.name
        // 加入匯款金額
        if (item.paymentNotification.createTime && item.paymentNotification.paymentTime) object.moneyTransfer = item.paymentNotification.money

        // 進階搜尋
        // 依照負責人搜尋
        let principalName = true
        if (this.advancedSearchData.principalName) {
          principalName = false
          if (item.paymentVerifyRecords.length > 0) {
            const index = item.paymentVerifyRecords.length - 1
            if (item.paymentVerifyRecords[index].administrativeUser.name.indexOf(this.advancedSearchData.principalName) !== -1) {
              principalName = true
            }
          }
        }
        // 依照發票號碼搜尋
        let receiptNumber = true
        if (this.advancedSearchData.receiptNumber) {
          receiptNumber = false
          const len = item.paymentVerifyRecords.length
          const index = item.paymentVerifyRecords.length - 1
          if (len > 0 && item.paymentVerifyRecords[index].receiptNumber) {
            if (item.paymentVerifyRecords[index].receiptNumber.indexOf(this.advancedSearchData.receiptNumber) !== -1) {
              receiptNumber = true
            }
          }
        }
        // 訂閱狀態
        let orderStatus = false
        let is_paymentNotification = false
        let is_verify = false
        let is_shipped = false
        let is_admin = false
        switch(this.advancedSearchData.singleSelect.orderStatus) {
          case '未完成':
            // 未回報，未審核，未寄出發票
            is_paymentNotification = !item.paymentNotification.createTime ? true : false
            is_verify = !item.paid && item.paymentVerifyRecords.length === 0
            is_shipped = !item.shipped
            if (object.type === 'normal') {
              if (is_paymentNotification || is_verify || is_shipped) orderStatus = true
            } else {
              if (is_verify) orderStatus = true
            }
            break
          case '已完成':
            // 已回報，已審核，已寄出發票
            is_paymentNotification = item.paymentNotification.createTime ? true : false
            is_verify = item.paid
            is_shipped = item.shipped
            if (object.type === 'normal') {
              if ((is_paymentNotification && is_verify && is_shipped) || is_admin) orderStatus = true
            } else {
              if (is_verify) orderStatus = true
            }
            break
          default:
            orderStatus = true
        }
        // 刪除狀態
        let delStatus = true
        switch(this.advancedSearchData.singleSelect.delStatus) {
          case '未刪除':
            delStatus = false
            if (!item.deleted) delStatus = true
            break
          case '已刪除':
            delStatus = false
            if (item.deleted) delStatus = true
            break
        }

        // 訂閱模式
        let orderMode = false
        switch (this.advancedSearchData.singleSelect.orderMode) {
          case '一般付費':
            if (object.planType === 'NORMAL') orderMode = true
            break;
          case '試用期':
            if (object.planType === 'FREE_TRIAL') orderMode = true
            break;
          case '推薦人':
            if (object.planType === 'RECOMMENDED_BONUS') orderMode = true
            break;
          case '輸入推薦碼的人':
            if (object.planType === 'NORMAL' && object.recommenderId) orderMode = true
            break;
          default:
            orderMode = true
            break;
        }
        if (orderMode) {
          console.log(item)
          console.log(principalName, receiptNumber, orderStatus, delStatus, orderMode)
        }
        if (principalName && receiptNumber && orderStatus && delStatus && orderMode) this.paymentRecordList.push(JSON.parse(JSON.stringify(object)))
      })
      this.dataTabelLoading = false
      this.$methods.switchLoading('hide')
    },
    // postalcode 轉換
    convertPostalCode(paymentRecord) {
      const vm = this
      this.$methods.switchLoading('show')
      const convertPostalCodeApi = `${process.env.VUE_APP_API}/search/getPostalCodeData?postalCode=${paymentRecord.postalCode}`
      const header = {
        authorization: this.b_serverToken
      }
      $.ajax({
        type: 'GET',
        async: false,
        url: convertPostalCodeApi,
        headers: header,
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            const results = res.data[0]
            vm.paymentRecordData.address = results.City + results.Area + paymentRecord.address
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 審核
    verifyPaymentRecord(verify) {
      const vm = this
      this.$methods.switchLoading('show')
      const verifyPaymentRecordApi = `${process.env.VUE_APP_API}/paymentVerifyRecord/create`
      const header = {
        authorization: this.b_serverToken
      }
      let data = []
      let object = {
        paymentRecordId: this.verifyData.paymentRecordId,
        verify: verify
      }
      if (this.verifyData.receiptNumber) {
        object.receiptNumber = this.verifyData.receiptNumber
      }
      if (this.verifyData.note) {
        object.note = this.verifyData.note
      }
      data.push(object)
      $.ajax({
        type: 'POST',
        async: true,
        url: verifyPaymentRecordApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.advancedSearch()
            vm.paymentNotificationModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })

    },
    // 編輯發票號碼
    updateReceipt() {
      const vm = this
      this.$methods.switchLoading('show')
      const updateReceiptApi = `${process.env.VUE_APP_API}/paymentVerifyRecord/updateReceiptNumber`
      const header = {
        authorization: this.b_serverToken
      }
      const index = this.subscribeProject.paymentVerifyRecords.length - 1
      const paymentVerifyRecordId = this.subscribeProject.paymentVerifyRecords[index].id
      const data = [
        {
          id: paymentVerifyRecordId,
          receiptNumber: this.receiptNumber
        }
      ]
      $.ajax({
        type: 'PUT',
        async: true,
        url: updateReceiptApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.advancedSearch()
            vm.editReceiptModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // 寄出發票
    sendReceipt() {
      const vm = this
      this.$methods.switchLoading('show')
      const sendReceiptApi = `${process.env.VUE_APP_API}/paymentRecord/setShipped`
      const header = {
        authorization: this.b_serverToken
      }
      if (!this.shippedTime) {
        this.$methods.switchLoading('hide')
        return this.SweetAlert('other', '請填寫發票寄出時間!')
      }
      const data = [
        {
          id: this.subscribeProject.id,
          shipped: true,
          shippedTime: new Date(this.shippedTime) * 1
        }
      ]
      $.ajax({
        type: 'PUT',
        async: true,
        url: sendReceiptApi,
        data: JSON.stringify(data),
        headers: header,
        contentType: 'application/json',
        success: function(res) {
          console.log(res)
          if (res.code === '200') {
            vm.SweetAlert(res.code, res.message)
            vm.advancedSearch()
            vm.shippedModal.hide()
          } else {
            vm.SweetAlert(res.code, res.message)
          }
          vm.$methods.switchLoading('hide')
        },
        error: function(err) {
          console.log(err.responseJSON)
          vm.SweetAlert(err.responseJSON.code, err.responseJSON.message)
          vm.$methods.switchLoading('hide')
        },
      })
    },
    // * 贈送 1500 積分 (截止至 2024/12/31 二周年活動)
    giveAway1500Integrall() {
      const now = new Date().getTime();
      const deadLine = new Date("2024/12/31 23:59:59").getTime();
      if (now > deadLine) return this.SweetAlert("other", "截止至 2024/12/31 二周年活動已到期"); 
      if (!this.paymentRecordData.recommenderId) return this.SweetAlert("other", "該訂單尚未輸入推薦碼"); 
      if (!this.paymentRecordData.note || (this.paymentRecordData.note && !this.paymentRecordData.note.match("2024-12-31前的優惠活動，新用戶&&年繳&&有推薦碼"))) return this.SweetAlert("other", "該訂單不符合贈送積分條件");

      this.$methods.switchLoading('show')
      const data = [
        {
          sourceType: 53,
          sourceId: this.paymentRecordData.id,
          physicalUserId: this.paymentRecordData.recommenderId,
          adjust: 1500,
          reason: "二周年活動推薦人獎勵 1500 積分"
        }
      ]
      userApi.adjustIntegrall(data).then(res => {
        console.log(res);
        if (res.code === "200") this.SweetAlert("200")
        else if (res.code === "206") {
          let errorMessage = "";
          res.errorData.forEach((message, index) => {
            errorMessage += `${index}. ${message}\n`
          })
          this.SweetAlert("other", errorMessage);
        }
      }).catch(err => {
        console.log(err);
        if (err.responseJSON) this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
      }).finally(() => {
        this.$methods.switchLoading('hide')
      })
    },
    // * 贈送 Line 關鍵字功能 (截止至 2024/09/30 二周年活動)
    giveAwayLineKeywords() {
      const now = new Date().getTime();
      const deadLine = new Date("2024/09/30 23:59:59").getTime();
      if (now > deadLine) return this.SweetAlert("other", "截止至 2024/09/30 二周年活動已到期");
      if (!this.paymentRecordData.recommenderId) return this.SweetAlert("other", "該訂單尚未輸入推薦碼");

      this.$methods.switchLoading('show')
      // 推薦人 跟 本人 都開啟 line 自定義功能
      const data = [
        {
          systemSettingId: null,
          physicalUserId: this.paymentRecordData.recommenderId,
          enabled: true,
        },
        {
          systemSettingId: null,
          physicalUserId: this.paymentRecordData.owner.id,
          enabled: true,
        },
      ];
      
      systemApi.setSystemSettingForLinekeywords(data).then(res => {
        console.log(res);
        if (res.code === "200") this.SweetAlert("200")
      }).catch(err => {
        console.log(err);
        if (err.responseJSON) this.SweetAlert(err.responseJSON.code, err.responseJSON.message)
      }).finally(() => {
        this.$methods.switchLoading('hide')
      });
    },
  },
}
</script>