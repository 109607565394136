// 引入 cookie 設定
import { setCookie, getCookie, delCookie } from '../../global/cookie'

class UserApi {
  /**
   * 調整使用者積分
   * @param {*} data 
   * @returns promise
   * 
   * data = [
   *  {
   *       "sourceType": 2(AdministrativeUser.class) || 53(PaymentRecord.class),
	 * 	 	   "sourceId" 來源 ID ( sourceType === 53 就放 PaymentRecord 的 ID || sourceType === 2 就放 admin 的 ID),
	 * 	     "physicalUserId": physicalUser 的 ID,
	 * 	     "adjust": 要調整的積分 (可正負),
	 * 	     "reason": 原因
	 * 	}...
   * ]
   */
  adjustIntegrall(data) {
    const api = `${process.env.VUE_APP_API}/user/adjustIntegrall`
    const header = {
      authorization: getCookie("b_serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "POST",
        async: true,
        url: api,
        headers: header,
        data: JSON.stringify(data),
        contentType: 'application/json',
        success: resolve,
        error: resject,
      });
    })
    return result
  }

  /**
   * 取得積分歷史紀錄
   * @returns promise
   */
  getIntegrallHistory() {
    const api = `${process.env.VUE_APP_API}/user/getIntegrallHistory`
    const header = {
      authorization: getCookie("serverToken")
    }
    const result = new Promise((resolve, resject) => {
      $.ajax({
        type: "GET",
        async: true,
        url: api,
        headers: header,
        success: resolve,
        error: resject,
      });
    })
    return result
  }
}

const userApi = new UserApi()

export { userApi }